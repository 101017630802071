<template>
  <section class="sign-in-page" style="overflow-y: scroll;">
    <div id="container-inside">
      <div id="circle-small"></div>
      <div id="circle-medium"></div>
      <div id="circle-large"></div>
      <div id="circle-xlarge"></div>
      <div id="circle-xxlarge"></div>
    </div>
    <div class="container p-0">
      <div class="row no-gutters">
        <div class="col-md-6 text-center">
          <div class="sign-in-detail text-white">
            <a class="sign-in-logo mb-2" href="#">
              <img
                src="@/assets/images/logo-full.png"
                class="img-fluid"
                alt="logo"
              />
            </a>
            <div class="swiper-area">
              <Swiper :options="options">
                <SwiperSlide class="item">
                  <img
                    src="@/assets/images/login/1.png"
                    class="img-fluid mb-1"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">{{ $t('auth.first.title') }}</h4>
                  <p>{{ $t('auth.first.subTitle') }}</p>
                </SwiperSlide>
                <SwiperSlide class="item">
                  <img
                    src="@/assets/images/login/2.png"
                    class="img-fluid mb-1"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">{{ $t('auth.second.title') }}</h4>
                  <p>{{ $t('auth.second.subTitle') }}</p>
                </SwiperSlide>
                <SwiperSlide class="item">
                  <img
                    src="@/assets/images/login/3.png"
                    class="img-fluid mb-1"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">{{ $t('auth.third.title') }}</h4>
                  <p>{{ $t('auth.third.subTitle') }}</p>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div class="col-sm-6 bg-white pt-5 pb-5">
          <div class="sign-in-from">
            <h1 class="mb-0">{{ $t('label.signUp') }}</h1>
            <p>{{ $t('label.signUpSubText') }}</p>
            <form class="mt-4" @submit.prevent="onSubmit()">

              <div class="form-group">
                <label class="form-label" for="account">{{
                  $t('label.account') 
                }} ({{ $t('label.registerCannotModify') }})</label>
                <input
                  type="account"
                  v-model="form.account"
                  class="form-control mb-0"
                  id="account"
                  name="account"
                  :placeholder="$t('label.userNamePlaceholder')"
                  :class="{
                    'is-invalid':
                      v$.form.account.$dirty &&
                      !v$.form.account.$pending &&
                      v$.form.account.$invalid
                  }"
                />
                <span
                  v-if="
                    v$.form.account.$dirty &&
                    !v$.form.account.$pending &&
                    v$.form.account.$invalid
                  "
                  class="invalid-feedback"
                  >{{ $t('messages.required') }}</span
                >
              </div>

              <div class="form-group">
                <label class="form-label" for="user_name">{{
                  $t('label.user_name')
                }}</label>
                <input
                  type="user_name"
                  v-model="form.user_name"
                  class="form-control mb-0"
                  id="user_name"
                  name="user_name"
                  :placeholder="$t('label.userNamePlaceholder')"
                  :class="{
                    'is-invalid':
                      v$.form.user_name.$dirty &&
                      !v$.form.user_name.$pending &&
                      v$.form.user_name.$invalid
                  }"
                />
                <span
                  v-if="
                    v$.form.user_name.$dirty &&
                    !v$.form.user_name.$pending &&
                    v$.form.user_name.$invalid
                  "
                  class="invalid-feedback"
                  >{{ $t('messages.required') }}</span
                >
              </div>
              <div class="form-group">
                <label class="form-label" for="email">{{
                  $t('label.email')
                }}</label>
                <input
                  type="email"
                  v-model="form.email"
                  class="form-control mb-0"
                  id="email"
                  name="email"
                  :placeholder="$t('label.emailPlaceholder')"
                  :class="{
                    'is-invalid':
                      v$.form.email.$dirty &&
                      !v$.form.email.$pending &&
                      v$.form.email.$invalid
                  }"
                />
                <span
                  v-if="
                    v$.form.email.$dirty &&
                    !v$.form.email.$pending &&
                    v$.form.email.$invalid
                  "
                  class="invalid-feedback"
                  >{{ $t('messages.required') }}</span
                >
              </div>
              <div class="form-group">
                <label class="form-label" for="exampleInputPassword1">{{
                  $t('label.password')
                }}</label>
                <div class="input-group">
                  <input
                    type="password"
                    v-model="form.password"
                    id="password"
                    name="password"
                    class="form-control"
                    :placeholder="$t('label.passwordPlaceholder')"
                    :class="{
                      'is-invalid':
                        v$.form.password.$dirty &&
                        !v$.form.password.$pending &&
                        v$.form.password.$invalid
                    }"
                  />
                  <span
                    v-if="
                      v$.form.password.$dirty &&
                      !v$.form.password.$pending &&
                      v$.form.password.$invalid
                    "
                    class="invalid-feedback"
                    >{{ $t('messages.required') }}</span
                  >
                </div>
              </div>
              <!-- <div class="form-group">
                <label class="form-label" for="exampleInputPassword1">{{
                  $t('label.tag')
                }}</label>
                <div class="input-group">
                  <el-select
                    size="large"
                    :class="{
                      'is-invalid':
                        v$.form.tags.$dirty &&
                        !v$.form.tags.$pending &&
                        v$.form.tags.$invalid
                    }"
                    v-model="form.tags"
                    multiple
                    :placeholder="$t('label.selectTag')"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in commomData.tagItems"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <span
                    v-if="
                      v$.form.tags.$dirty &&
                      !v$.form.tags.$pending &&
                      v$.form.tags.$invalid
                    "
                    class="invalid-feedback"
                    >{{ $t('messages.required') }}</span
                  >
                </div>
              </div> -->
              <PreferTagList :userForm="form" :addTags="addTags" @getTagList="getTagList" />
              <div class="d-flex d-flex justify-content-between w-100">
                <div
                  class="custom-control custom-checkbox d-inline-block"
                >
                  <input
                    type="checkbox"
                    v-model="checkBox"
                    class="custom-control-input"
                    id="checkBox"
                    name="checkBox"
                    :class="{ 'is-invalid': v$.checkBox.$invalid }"
                  />
                  <label class="custom-control-label ps-1" for="checkBox">
                    {{ $t('label.iAccept') }}
                    <a href="#">{{ $t('label.termsOfUserRights') }}</a></label
                  >
                  <span v-if="v$.checkBox.$invalid" class="invalid-feedback">{{
                    $t('messages.required')
                  }}</span>
                </div>
                <div style="text-align: right;">
                  <button type="submit" class="btn btn-primary">
                    <i
                      v-show="commomData.btnLoader"
                      class="fa fa-spinner fa-spin"
                    ></i>
                    {{ $t('label.signUp') }}
                  </button>
                  <div class="mt-1 text-danger" style="font-size: 0.875em;">請檢查垃圾郵件夾以防驗證信遺漏</div>
                </div>
              </div>
              <div class="sign-info">
                <span class="dark-color d-inline-block line-height-2"
                  >{{ $t('label.alreadyHaveAnAccount') }}
                  <router-link :to="{ name: 'auth1.sign-in' }">
                    {{ $t('label.signIn') }}</router-link
                  >
                </span>
                <ul class="iq-social-media">
                  <li>
                    <a href="#"><i class="ri-google-line"></i></a>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swiper from '../../../components/custom/slider/Swiper'
import SwiperSlide from '../../../components/custom/slider/SwiperSlide'
import { signUp, checkAccount } from '../../../api/auth'
import useVuelidate from '@vuelidate/core'
import { required, minLength, email, sameAs } from '@vuelidate/validators'
import { commomData } from '@/utils/commonVmodel'
import PreferTagList from '@/views/User/Components/PreferTagList'
export default {
  name: 'SignUp',
  components: { Swiper, SwiperSlide, PreferTagList },
  setup() {
    const v$ = useVuelidate()
    return { v$ }
  },
  data() {
    return {
      commomData: commomData(),
      options: {
        centeredSlides: false,
        loop: false,
        slidesPerView: 1,
        autoplay: {
          delay: 3000
        },
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      },
      form: {
        user_name: '',
        password: '',
        email: '',
        signup_type: 1,
        tags: [],
        referral: '',
        account: ''
      },
      checkBox: false,
      normalTag: [],
      adultTag: []
    }
  },
  validations() {
    return {
      form: {
        account: { required },
        user_name: { required },
        email: { required, email },
        password: { required, minLength: minLength(5) },
        // tags: { required, minLength: minLength(1) }
      },
      checkBox: {
        sameAs: sameAs(true)
      }
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      const containTag1 = this.arrHasSameValue(this.normalTag, this.form.tags);
      const containTag2 = this.arrHasSameValue(this.adultTag, this.form.tags);

      if (containTag1.length === 0 || containTag2.length === 0) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: '一般和成人喜好內容需都至少選擇一項'
        })
        return;
      }

      this.commomData.btnLoader = true
      this.form.referral = this.$route.query.ref ? this.$route.query.ref : ''

      checkAccount({ account: this.form.account })
        .then((res1) => {
          console.log(res1)
          signUp(this.form)
            .then((res) => {
              this.$swal.mixin().fire({
                icon: 'sessues',
                text: res.message
              })
              this.commomData.btnLoader = false
            })
            .catch((err) => {
              this.$swal.mixin().fire({
                icon: 'error',
                text: err.response.data.message
              })
              this.commomData.btnLoader = false
            })
        })
        .catch((err) => {
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
          this.commomData.btnLoader = false
        })

      
    },
    addTags(tagName) {
      if (this.form.tags.includes(tagName)) {
        this.form.tags = this.form.tags.filter(item => item !== tagName)
        return
      }
      this.form.tags.push(tagName)
    },
    getTagList(obj) {
      const { adult, notAdult } = obj;
      this.adultTag = adult;
      this.normalTag = notAdult
    },
    arrHasSameValue(arr1, arr2) {
      return arr1.filter(item => {
          if (arr2.indexOf(item.name) > -1) {
            console.log(item)
              return item;
          }
      })
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
.swiper-area {
  overflow-x: hidden;
}
</style>
